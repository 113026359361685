import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Badge } from "reactstrap";

const propTypes = {
	children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
	render() {
		// eslint-disable-next-line
		const { children, ...attributes } = this.props;

		const frontentVersion = `1.0.${process.env.REACT_APP_VERSION}`;
		const backendVersion = this.props.apiKeys
			? this.props.apiKeys.version === frontentVersion
				? "" // Identical versions -> hide
				: "-" + this.props.apiKeys.version // different versions -> show both
			: "-?"; // backend not leaded yet

		const dbsuffix =
			this.props.apiKeys && this.props.apiKeys.environment !== "Production"
				? this.props.apiKeys.environment[0]
				: "";

		return (
			<div className="footerBox">
				<div className="footerContainer">
					<div className="footerColumn footerLogo">
						<img
							src="/footer-logo.png"
							alt=""
							width="228px"
							height="18.6px"
							className="mr-2 mt-1"
						/>
					</div>
					<div className="footerRow">
						<div className="footerColumn">


							<a className="footerLink" href="/about">
								About Us
							</a>

						</div>
						<div className="footerColumn">
							<a className="footerLink" href="/register">
								Register
							</a>
						</div>

						<div className="footerColumn">
							<a className="footerLink" href="/contact">
								Contact
							</a>
						</div>


						{/* <div className="footerColumn">
							<p className="footerHeading">COMPANY</p>
							<a className="footerLink" href="/about">
								About Us
							</a>
							<a className="footerLink" href="/register">
								Register
							</a>
							<a className="footerLink" href="/contact">
								Contact
							</a>
						</div> */}

						{/* <div className="footerColumn">
							<p className="footerHeading">ABOUT</p>
							<a className="footerLink" href="/terms-and-conditions">
								Terms & Conditions
							</a>

						</div> */}
					</div>
				</div>
				<div className="pt-5 footerCopyRight">
					<span>
						Copyright © 2025 Local Carbon Registry

					</span>
					<span style={{ paddingLeft: "10px" }}>
						v{frontentVersion}
						{backendVersion} {dbsuffix}
						
						{this.props.apiKeys && this.props.apiKeys.environment !== "Production" && (
							<Badge className="ml-2" color="danger">
								Test
							</Badge>
						)}
					</span>
				</div>
			</div>
		);
	}
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

function mapStateToProps(state) {
	return {
		apiKeys: state.settings.apiKeys,
	};
}

export default connect(mapStateToProps, {})(DefaultFooter);
